const ApplySticky = (jobDetails) => {
    return (
        <div class = "bg-white px-4 md:px-5 lg:px-10 py-8 drop-shadow-xl rounded-3xl md:sticky top-40">
            <p class = 'paragraph-one-bold text-sub-heading-mobile md:text-paragraph-one text-left'>
                Summary
            </p>
            <div class = "grid grid-cols-12 gap-x-4 gap-y-6 md:gap-y-8 pt-5">
                <div class = "col-span-2 h-full flex items-center">
                    <img src={require('./../../assets/Images/Icons/industry.png')} class = "px-0.5 w-full"/>
                </div>
                <div class = "h-full col-span-10 text-left paragraph-four-bold text-paragraph-two-mobile md:text-paragraph-four">
                    {jobDetails["Industry"].map((industry, i, industries) => (
                        <>
                        {(i == industries.length - 1)?
                            <span>{industry} </span>:
                            <span>{industry}, </span>
                        }
                    </>
                    ))}
                    <p class = "text-paragraph-five-mobile md:text-paragraph-five paragraph-five leading-[10px] text-stone-300">
                        Industry Type
                    </p>
                </div>
                <div class = "col-span-2 h-full flex items-center">
                    <img src={require('./../../assets/Images/Icons/jobType.png')} class = "px-0.5 w-full"/>
                </div>
                <div class = "h-full col-span-10 text-left paragraph-four-bold text-paragraph-two-mobile md:text-paragraph-four">
                    {jobDetails["Job Type"].map((jobType, i, jobTypes) => (
                        <>
                        {(i == jobTypes.length - 1)?
                            <span>{jobType} </span>:
                            <span>{jobType}, </span>
                        }
                    </>
                    ))}
                    <p class = "text-paragraph-five-mobile md:text-paragraph-five paragraph-five leading-[10px] text-stone-300">
                        Employment Type
                    </p>
                </div>
                <div class = "col-span-2 h-full flex items-center md:items-center">
                    <img src={require('./../../assets/Images/Icons/location.png')} class = "px-0.5 w-full"/>
                </div>
                <div class = "h-full col-span-10 text-left paragraph-four-bold text-paragraph-two-mobile md:text-paragraph-four">
                    {jobDetails["Location"].map((location, i, locations) => (
                        <>
                        {(i == locations.length - 1)?
                            <span>{location} </span>:
                            <span>{location}, </span>
                        }
                    </>
                    ))}
                    <p class = "text-paragraph-five-mobile md:text-paragraph-five paragraph-five leading-[10px] text-stone-300">
                    Available Location(s)
                    </p>
                </div>
                <div class = "col-span-2 h-full flex items-center">
                    <img src={require('./../../assets/Images/Icons/prReq.png')} class = "px-0.5 w-full"/>
                </div>
                <div class = "h-full col-span-10 text-left paragraph-four-bold text-paragraph-two-mobile md:text-paragraph-four">
                    {jobDetails["PR Requirement"]}
                    <p class = "text-paragraph-five-mobile md:text-paragraph-five paragraph-five leading-[10px] text-stone-300">
                        PR Requirement
                    </p>
                </div>
                <div class = "col-span-2 h-full flex items-center">
                    <img src={require('./../../assets/Images/Icons/calendar.png')} class = "px-0.5 w-full"/>
                </div>
                <div class = "h-full col-span-10 text-left paragraph-four-bold text-paragraph-two-mobile md:text-paragraph-four">
                Apply By - <span>
                        {jobDetails["Application Deadline"] ? <>{jobDetails["Application Deadline"].substring(8,10)}/{jobDetails["Application Deadline"].substring(5,7)}/{jobDetails["Application Deadline"].substring(0,4)}</> :
                         "NA"} 
                    </span>
                    <p class = "text-paragraph-five-mobile md:text-paragraph-five paragraph-five leading-[10px] text-stone-300">
                    Application Date
                    </p>
                </div>
                <div class = "col-span-12 relative mt-7 md:mt-0 md:pb-10">
                    <a href = {jobDetails["Application Link"]} class = "px-3 py-2 md:absolute right-0 button text-button-mobile md:text-button w-fit rounded-3xl bg-secondary-yellow text-white">
                        Apply <span>
                        <svg class="w-5 inline" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
  };

export default ApplySticky;